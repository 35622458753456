/* eslint-disable react/jsx-no-target-blank */
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Checkout.css';
import Loader from 'react-loader-spinner';
import ReactPlayer from 'react-player';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { InfoIcon } from 'lucide-react';
import { faPersonBooth, faPlay } from '@fortawesome/free-solid-svg-icons';
import ICheckoutProps from '../../domain/interfaces/containers/ICheckoutProps';
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Table
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ISubscription,
  ITrainingConsultation,
  ITrainingVideo
} from '../../domain/interfaces/api/ITraining';
import { ServiceTypeEnum } from '../../domain/enums/ServiceTypeEnum';
import {
  createOrUpdateEmail,
  getGrossValue,
  getSalesmanagoCartEvent,
  getSalesmanagoPurchaseEvent
} from '../../domain/utils';
import { PaymentTypeEnum } from '../../domain/enums/PaymentTypeEnum';
import { Formik } from 'formik';
import * as Yup from 'yup';
import helpers from '../../util/helpers';
import FormikField from '../../components/FormikField/FormikField';
import CheckoutInvoiceFormView from '../../domain/views/components/CheckoutInvoiceFormView';
import PurchaseModel from '../../domain/models/api/PurchaseModel';
import { IEditManual } from '../../domain/interfaces/api/IPurchase';
import apiServices from '../../services/api-axios';
import { INIPData } from '../../domain/interfaces/api/INIPResponse';
import { toast } from 'react-toastify';
import IMetadata from '../../domain/interfaces/api/IMetadata';
import salesmanago from '../../services/external/salesmanago';

const Checkout: FC<ICheckoutProps> = React.memo((props): ReactElement => {
  const { t } = useTranslation();
  const [hasPccAccount, setHasPccAccount] = useState(true);

  const handleSwitchChange = () => {
    setHasPccAccount(!hasPccAccount);
  };

  const [isCheckedInvoice, setIsCheckedInvoice] = useState<boolean>(false);
  const [isCheckedBuyer, setIsCheckedBuyer] = useState<boolean>(true);

  const [hideInvoice, setHideInvoice] = useState<boolean>(false);
  const [showDiffPayer, setShowDiffPayer] = useState<boolean>(false);

  const [manualEditing, setManualEditing] = useState<string[]>([]);
  const [methodSelected, setMethodSelected] = useState<PaymentTypeEnum>();
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [submitingPayment, setSubmitingPayment] = useState<boolean>(false);
  const [loadingNipData, setLoadingNipData] = useState<boolean>(false);
  const [loadedNipData, setLoadedNipData] = useState<{ string: INIPData }>();
  const [metadata, setMetadata] = useState<IMetadata | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  let history = useHistory();

  const invoiceFormRef = useRef<any | null>();
  const payerFormRef = useRef<any | null>();
  const registerFormRef = useRef<any | null>();
  const registerFormWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    salesmanago.createExternalEvent({
      ...getSalesmanagoCartEvent()
    });

    apiServices
      .getMetadata()
      .then((response) => {
        setMetadata(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation<{
    element: ITrainingConsultation | ITrainingVideo;
    type: ServiceTypeEnum;
    subscription: ISubscription;
  }>();

  const element = location.state?.element;
  const type = location.state?.type;
  const subscription = location.state?.subscription;

  useEffect(() => {
    setHideInvoice(isCheckedInvoice);
  }, [isCheckedInvoice]);

  // if (!element || !type || !subscription) {
  //   return (
  //     <div className="container-error">
  //       <TriangleAlertIcon size={36} color="#d87070" />
  //       <p>{t('containers.checkout.error.message')}</p>
  //       <Link to="/">{t('containers.checkout.error.link')}</Link>
  //     </div>
  //   );
  // }

  const removeForm = (form: string) => {
    const copyArray = [...manualEditing];

    var filtered = copyArray.filter(function (value, index, arr) {
      return value !== form;
    });

    setManualEditing(filtered);
  };

  const invoiceFormSchema = Yup.object().shape({
    hideInvoice: Yup.boolean(),
    name: Yup.string().required(t('containers.checkout.required_field')),
    post_code: Yup.string()
      .required(t('containers.checkout.required_field'))
      .matches(/^(\d{2}-\d{3})$/g, 'Musi przestrzegać formatu XX-XXX'),
    nip: Yup.string()
      .matches(/^(\d{10})$/g, 'Musi przestrzegać formatu XXXXXXXXXX')
      .when('hideInvoice', {
        is: false,
        then: Yup.string().required(t('containers.checkout.required_field'))
      }),
    voivodeship: Yup.string().when('hideInvoice', {
      is: true,
      then: Yup.string().required(t('containers.checkout.required_field'))
    }),
    city: Yup.string().when('hideInvoice', {
      is: true,
      then: Yup.string().required(t('containers.checkout.required_field'))
    }),
    address: Yup.string().when('hideInvoice', {
      is: true,
      then: Yup.string().required(t('containers.checkout.required_field'))
    })
  });

  const registerFormSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('containers.checkout.required_field'))
      .email(),
    name: Yup.string().required(t('containers.checkout.required_field')),
    surname: Yup.string().required(t('containers.checkout.required_field'))
  });

  const renderForm = (form: string, formData?: INIPData) => {
    let disableField = !manualEditing.includes(form);

    if (isCheckedInvoice) {
      disableField = false;
    }

    return (
      <Formik
        innerRef={form === 'invoice' ? invoiceFormRef : payerFormRef}
        enableReinitialize
        initialValues={{
          hideInvoice,
          nip: (formData && formData.nip) || '',
          name: (formData && formData.name) || '',
          voivodeship: (formData && formData.voivodeship) || '',
          city: (formData && formData.city) || '',
          post_code: (formData && formData.post_code) || '',
          address: (formData && formData.address) || ''
        }}
        validationSchema={invoiceFormSchema}
        onSubmit={() => {}}
      >
        {() => (
          <Form>
            {!isCheckedInvoice && (
              <Row className="align-items-center mt-4">
                <Col xs="6">
                  <FormikField
                    name="nip"
                    label={t('containers.checkout.tax_number_label')}
                    formGroupClassName="mb-3"
                    required={!hideInvoice}
                    formControl={(props) => (
                      <>
                        <Form.Control
                          type="text"
                          data-testid="form-nip"
                          placeholder="XXXXXXXXXX"
                          {...props}
                          onChange={(e) => {
                            const currValue = e.target.value;

                            if (currValue.length === 10) {
                              setLoadingNipData(true);

                              apiServices
                                .getEntityDataByNip(currValue)
                                .then((response) => {
                                  const { status, data } = response.data;
                                  if (status === 1 && data) {
                                    let loadedData = {
                                      ...loadedNipData
                                    };

                                    loadedData[form] = data;

                                    setLoadedNipData(
                                      loadedData as { string: INIPData }
                                    );
                                  } else {
                                    let loadedData = {};
                                    loadedData[form] = {
                                      nip: currValue
                                    };

                                    setLoadedNipData(
                                      loadedData as { string: INIPData }
                                    );
                                  }
                                })
                                .catch((error) => {
                                  console.error(error);
                                })
                                .finally(() => {
                                  setLoadingNipData(false);
                                });
                            } else if (loadedNipData && currValue) {
                              let loadedData = {};
                              loadedData[form] = {
                                nip: currValue
                              };

                              setLoadedNipData(
                                loadedData as { string: INIPData }
                              );
                            }

                            props.onChange(e);
                          }}
                        />
                        <Form.Text className="text-muted">
                          {t('containers.checkout.tax_number_info')}
                        </Form.Text>
                      </>
                    )}
                  />
                </Col>
                <Col xs="6">
                  <Form.Check
                    id={`check-manual-${form}`}
                    label={t('containers.checkout.form_edit')}
                    type={'checkbox'}
                    onChange={(event) => {
                      const copyArray = [...manualEditing];

                      if (event.target.checked) {
                        copyArray.push(form);

                        setManualEditing(copyArray);
                      } else {
                        removeForm(form);
                      }
                    }}
                    checked={!disableField}
                  />
                </Col>
              </Row>
            )}

            <FormikField
              name="name"
              label={t('containers.checkout.form_name')}
              disabled={disableField}
              formGroupClassName="mb-3"
              required
              testId="form_name"
            />

            <FormikField
              name="voivodeship"
              label={t('containers.checkout.form_voivodeship_label')}
              disabled={disableField}
              formGroupClassName="mb-3"
              required={hideInvoice}
              formControl={(props) => (
                <Form.Select
                  aria-label="Default select example"
                  data-testid="form_voivodeship"
                  {...props}
                >
                  {metadata &&
                    metadata.voivodeships &&
                    Object.keys(metadata.voivodeships).map((value, i) => {
                      if (value) {
                        return (
                          <option key={i} value={value}>
                            {`${metadata.voivodeships[value]
                              .substring(0, 1)
                              .toUpperCase()}${metadata.voivodeships[
                              value
                            ].substring(1)}`}
                          </option>
                        );
                      } else {
                        return null;
                      }
                    })}{' '}
                  :
                  {
                    <option key="1" value="value">
                      puste
                    </option>
                  }
                </Form.Select>
              )}
            />

            <FormikField
              name="city"
              label={t('containers.checkout.form_city_label')}
              disabled={disableField}
              formGroupClassName="mb-3"
              required={hideInvoice}
              testId="form_city_label"
            />

            <FormikField
              name="post_code"
              label={t('containers.checkout.form_zip_label')}
              disabled={disableField}
              formGroupClassName="mb-3"
              required
              testId="form_zip_label"
            />

            <FormikField
              name="address"
              label={t('containers.checkout.form_address_label')}
              disabled={disableField}
              formGroupClassName="mb-3"
              required={hideInvoice}
              formControl={(props) => (
                <Form.Control
                  as="textarea"
                  style={{ height: '100px' }}
                  {...props}
                  data-testid="form_address_label"
                />
              )}
            />
          </Form>
        )}
      </Formik>
    );
  };

  const renderPaymentMethod = (
    method: PaymentTypeEnum,
    title: string,
    imgPath: string,
    body: string
  ) => (
    <div
      className={`d-flex flex-column mb-4 checkout-pay-method-wrapper${
        methodSelected === method ? ' selected' : ''
      }`}
    >
      <div className="d-flex p-2 align-items-center">
        <Form.Check
          inline
          id={`radio-${title}`}
          label={title}
          type="radio"
          className="flex-grow-1"
          name="group1"
          onChange={(event) => {
            if (event.target.checked) {
              setMethodSelected(method);
            }
          }}
          checked={methodSelected === method}
        />

        <Image loading="lazy" src={imgPath} height="30px" fluid />
      </div>

      <p className="checkout-pay-method-body p-2 mb-0">{body}</p>
    </div>
  );

  const submitPayment = () => {
    setErrorMessage('');

    let invoiceData: CheckoutInvoiceFormView | undefined;
    let payerData: CheckoutInvoiceFormView | undefined;
    let registerData: any | undefined;

    if (invoiceFormRef.current) {
      const invoiceForm = invoiceFormRef.current;

      invoiceForm.handleSubmit();

      if (invoiceForm.isValid) {
        invoiceData = invoiceForm.values;
      }
    }

    if (payerFormRef.current) {
      const payerForm = payerFormRef.current;

      payerForm.handleSubmit();

      if (payerForm.isValid) {
        payerData = payerForm.values;
      }
    }

    if (registerFormRef.current) {
      const registerForm = registerFormRef.current;

      registerForm.handleSubmit();

      if (registerForm.isValid) {
        registerData = registerForm.values;
      } else if (registerFormWrapperRef.current) {
        registerFormWrapperRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
        registerFormWrapperRef.current.focus({ preventScroll: true });
      }
    }

    if (registerData && invoiceData) {
      const trainingId = Number(element.id);
      let purchaseModel = new PurchaseModel(trainingId, subscription.id);

      purchaseModel.setRegisterForm(
        registerData.email,
        registerData.name,
        registerData.surname,
        !hideInvoice
      );

      purchaseModel.setFrontendOrderSettings(!hideInvoice, methodSelected!);

      let editManual: IEditManual = {
        1: '0'
      };

      if (manualEditing.indexOf('invoice') >= 0) {
        editManual[1] = '1';
      }

      if (manualEditing.indexOf('payer') >= 0) {
        editManual[2] = '1';
      } else if (showDiffPayer) {
        editManual[2] = '0';
      }

      purchaseModel.setForumInvoiceDetails(editManual, invoiceData, payerData);

      setSubmitingPayment(true);

      apiServices
        .postPurchase(purchaseModel.toJSON())
        .then((response) => {
          const { data } = response;

          if (data.status === 1) {
            if (methodSelected === PaymentTypeEnum.BANK) {
              //dismiss loader
              setSubmitingPayment(false);

              //for pixel code tests
              var nextUrl = data.response.newUrl.replace(
                'podsumowanie',
                'zamowienie'
              );

              history.push({
                pathname: `${nextUrl}`,
                state: {
                  element,
                  subscription,
                  serviceType: type,
                  paymentType: methodSelected
                }
              });
            } else {
              //dismiss loader
              setSubmitingPayment(false);

              //for pixel code tests
              var newUrl = data.response.newUrl.replace(
                `${process.env.REACT_APP_CALLBACK_DOTPAY}/podsumowanie`,
                `${process.env.REACT_APP_CALLBACK_DOTPAY}/zamowienie`
              );
              // window.location.href = newUrl;
              //history.push(newUrl);
              window.location.href = newUrl;
            }

            // Update client name
            if (registerData) {
              const fullName = `${registerData.name}${
                registerData.surname && ' ' + registerData.surname
              }`;

              createOrUpdateEmail(registerData.email, fullName);
            }

            // Purchase event
            salesmanago.createExternalEvent({
              ...getSalesmanagoPurchaseEvent(Number(subscription.net_price), {
                detail1: subscription.net_price
              })
            });
          } else if (registerFormWrapperRef.current) {
            registerFormWrapperRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });

            //dismiss loader
            setSubmitingPayment(false);

            registerFormWrapperRef.current.focus({ preventScroll: true });

            if (data.response.notificationContent) {
              toast.error(data.response.notificationContent);

              if (
                data.response.errors &&
                data.response.errors.errors &&
                data.response.errors.errors.RegisterForm &&
                data.response.errors.errors.RegisterForm.email
              )
                setErrorMessage(
                  data.response.errors.errors.RegisterForm.email[0]
                );
            }
          }
        })
        .catch((error) => {
          toast.error('Server Error');
          console.error(error);

          //dismiss loader
          setSubmitingPayment(false);
        });
    }
  };

  const formattedNumber = new Intl.NumberFormat('pl-PL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(parseFloat(subscription.net_price));

  return (
    <div
      className="train-bg-wrapper mb-5"
      style={{
        backgroundImage: `url(/img/vector-2.png)`,
        backgroundRepeat: 'no-repeat',
        paddingTop: 150,
        backgroundSize: '47% auto',
        backgroundPosition: '104% 65px'
      }}
    >
      <Container>
        <Row className="align-items-center mb-5">
          <Col md={5}>
            <p className="checkout-title">{element.name}</p>
            <p className="checkout-subtitle">
              {t('containers.checkout.subtitle')}
            </p>

            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon icon={faCheckCircle} size="sm" color="#303A4D" />

              <p className="mb-0 ms-2 access-period-label">
                <strong>{t('containers.checkout.name_label')}</strong>{' '}
                {subscription.name}
              </p>
            </div>

            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon icon={faCheckCircle} size="sm" color="#303A4D" />

              <p className="mb-0 ms-2 access-period-label">
                <strong
                  dangerouslySetInnerHTML={{
                    __html: t('containers.checkout.access_preriod_label')
                  }}
                />{' '}
                {subscription.number_of_days}
              </p>
            </div>

            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon icon={faCheckCircle} size="sm" color="#303A4D" />

              <p className="mb-0 ms-2 access-period-label">
                <strong>{t('containers.checkout.net_price_label')}</strong>
                {` PLN ${formattedNumber}`}
              </p>
            </div>

            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon icon={faCheckCircle} size="sm" color="#303A4D" />

              <p className="mb-0 ms-2 access-period-label">
                <strong>{t('containers.checkout.gross_price_label')}</strong>
                {` PLN ${getGrossValue(subscription.net_price)} (${t(
                  'containers.training_details.price_tax_description',
                  {
                    price: subscription.net_price,
                    vatRule: helpers.getVatRule(type)
                  }
                )})`}
              </p>
            </div>
          </Col>
          <Col md={7}>
            {element.trailer_url ? (
              //client wants like that(like netflix dmc++)
              <ReactPlayer
                muted={process.env.NODE_ENV !== 'test'}
                controls
                width="100%"
                url={element.trailer_url}
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload' //<- this is the important bit
                    }
                  }
                }}
              />
            ) : (
              <Image
                loading="lazy"
                height={400}
                src={helpers.formatTrainingImage(element.image_url)}
                className="w-100 border-10"
              />
            )}
          </Col>
        </Row>

        <Row className="px-4 justify-content-center">
          <Col className="pricing-selector mt-2 flex-grow-0 px-0">
            <div
              className={`pricing-inner-selector${
                hasPccAccount ? ' active' : ''
              }`}
              onClick={() => handleSwitchChange()}
            >
              <FontAwesomeIcon icon={faPlay} size="2x" />
              <span className="ms-1">{`${t(
                'containers.checkout.buy_without_account'
              )} `}</span>
            </div>
            <div
              className={`pricing-inner-selector${
                !hasPccAccount ? ' active' : ''
              }`}
              onClick={() => handleSwitchChange()}
            >
              <FontAwesomeIcon icon={faPersonBooth} size="2x" />
              <span className="ms-1">
                {t('containers.checkout.buy_with_account')}
              </span>
            </div>
          </Col>
        </Row>

        {!hasPccAccount ? (
          <Row className="mb-3">
            <div className="mt-2 d-flex align-items-center justify-content-center">
              <p className="checkout-login-wrapper p-4 mb-0">
                {`${t('containers.checkout.login_label')} `}
                <a
                  className="text-white"
                  target="_blank"
                  href="https://app.pccporada.pl/users/auth/login"
                >
                  {t('containers.checkout.login_label_link')}
                </a>
              </p>
            </div>
          </Row>
        ) : (
          <div>
            <Row
              className="checkout-user-wrapper mb-2"
              ref={registerFormWrapperRef}
            >
              <p className="checkout-login-wrapper p-4 mb-0">
                {/* {`${t('containers.checkout.login_label')} `}
              <a
                className="text-white"
                href="https://app.pccporada.pl/users/auth/login"
              >
                {t('containers.checkout.login_label_link')}
              </a> */}
              </p>

              <p className="checkout_contact_label pt-3 px-5 bg-white">
                {t('containers.checkout.contact_label')}
              </p>

              <Formik
                innerRef={registerFormRef}
                enableReinitialize
                initialValues={{
                  email: '',
                  name: '',
                  surname: ''
                }}
                validationSchema={registerFormSchema}
                onSubmit={() => {}}
              >
                {() => (
                  <Form className="bg-white">
                    <Row>
                      {errorMessage && (
                        <p className="ps-md-5 invalid-feedback d-block">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: errorMessage
                            }}
                          />
                        </p>
                      )}
                      <Col md={4} className="ps-md-5">
                        <FormikField
                          name="email"
                          label={t('containers.checkout.form_email')}
                          formGroupClassName="mb-3"
                          required
                          formControl={(props) => (
                            <Form.Control
                              type="email"
                              data-testid="register-email"
                              {...props}
                            />
                          )}
                        />
                      </Col>
                      <Col md={4}>
                        <FormikField
                          name="name"
                          label={t('containers.checkout.form_name')}
                          formGroupClassName="mb-3"
                          required
                          testId="register-first-name"
                        />
                      </Col>
                      <Col md={4} className="pe-md-5">
                        <FormikField
                          name="surname"
                          label={t('containers.checkout.form_surname')}
                          formGroupClassName="mb-3"
                          required
                          testId="register-surname"
                        />
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Row>

            <Row className="mt-5">
              <Col>
                <Row>
                  <Col md={9} className="center">
                    <div className="wrapper-checked-invoice">
                      <p className="checkout_contact_label mb-0">
                        {t('containers.checkout.diff_payer_header')}
                      </p>
                      <Form.Check
                        id="check-diff-invoice"
                        label={t('containers.checkout.invoice_checkbox')}
                        type={'checkbox'}
                        onChange={(event) =>
                          setIsCheckedInvoice(event.target.checked)
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={isCheckedInvoice ? 12 : 6}>
                    {!isCheckedInvoice && (
                      <div className="wrapper-checkbox-with-info">
                        <span style={{ fontWeight: 'bold' }}>
                          {t('containers.checkout.buyer')}
                        </span>
                        {/* <Form.Check
                          id="check-diff-buyer"
                          label={t('containers.checkout.buyer')}
                          type={'checkbox'}
                          checked={isCheckedBuyer}
                          onChange={(event) => {
                            setIsCheckedBuyer(event.target.checked);
                            setShowDiffPayer(false);
                          }}
                        /> */}
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-buyer">
                              {t('containers.checkout.info-tooltip')}
                            </Tooltip>
                          }
                        >
                          <InfoIcon size={20} />
                        </OverlayTrigger>
                      </div>
                    )}

                    {renderForm(
                      'invoice',
                      !loadingNipData &&
                        loadedNipData &&
                        Object.keys(loadedNipData).indexOf('invoice') >= 0 &&
                        loadedNipData['invoice']
                    )}
                  </Col>

                  {!isCheckedInvoice && (
                    <Col md={6}>
                      <div className="wrapper-checkbox-with-info">
                        <Form.Check
                          id="check-diff-payer"
                          label={
                            <span>
                              <strong>
                                {t('containers.checkout.diff_payer_checkbox')}
                              </strong>
                            </span>
                          }
                          type={'checkbox'}
                          checked={showDiffPayer}
                          onChange={(event) => {
                            setShowDiffPayer(event.target.checked);
                            setIsCheckedBuyer(false);
                          }}
                        />
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-buyer">
                              {t('containers.checkout.info-tooltip')}
                            </Tooltip>
                          }
                        >
                          <InfoIcon size={20} />
                        </OverlayTrigger>
                      </div>

                      {showDiffPayer &&
                        !isCheckedBuyer &&
                        renderForm(
                          'payer',
                          !loadingNipData &&
                            loadedNipData &&
                            Object.keys(loadedNipData).indexOf('payer') >= 0 &&
                            loadedNipData['payer']
                        )}
                    </Col>
                  )}
                </Row>
              </Col>

              <Col md={4}>
                <p className="checkout_contact_label">
                  {t('containers.checkout.order_header')}
                </p>

                <Table className="train-table">
                  <thead>
                    <tr>
                      <th>{t('containers.checkout.product_label')}</th>
                      <th className="text-end">
                        {t('containers.training_details.order_total_label')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td valign="middle">{subscription.name}</td>
                      <td className="text-end" valign="middle">
                        PLN {getGrossValue(subscription.net_price)}
                      </td>
                    </tr>
                    <tr>
                      <td className="total" valign="middle">
                        {t('containers.training_details.order_total_label')}
                      </td>
                      <td className="text-end price" valign="middle">
                        {`PLN ${getGrossValue(subscription.net_price)}`}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <p className="train-small">
                  {t('containers.training_details.price_tax_description', {
                    price: subscription.net_price,
                    vatRule: helpers.getVatRule(type)
                  })}
                </p>

                <p className="checkout-pay-method-header mt-4">
                  {t('containers.checkout.pay_method_header')}
                </p>

                <div className="d-flex flex-column px-2">
                  <Form>
                    {renderPaymentMethod(
                      PaymentTypeEnum.DOTPAY,
                      'Przelewy24',
                      'img/Przelewy24_logo.png',
                      t('containers.checkout.pay_method_dotpay_body')
                    )}
                    {renderPaymentMethod(
                      PaymentTypeEnum.BANK,
                      'Transfer bankowy',
                      'img/banktransfer.png',
                      t('containers.checkout.pay_method_bank_body')
                    )}
                  </Form>

                  <Form.Check
                    id="check-terms"
                    data-testid="check-terms"
                    label={
                      <p>
                        {' '}
                        Akceptuję
                        <a href="/regulament" target="_blank" rel="noreferrer">
                          {' '}
                          regulamin sklepu pccporada.pl{' '}
                        </a>
                      </p>
                    }
                    type="checkbox"
                    className="flex-grow-1 mb-4"
                    onChange={(e) => {
                      setAcceptTerms(e.target.checked);
                    }}
                  />
                  {!submitingPayment ? (
                    <Button
                      className="train-button-purchase buy w-100"
                      disabled={!(methodSelected && acceptTerms)}
                      onClick={() => submitPayment()}
                    >
                      {t('containers.checkout.pay_btn_label')}
                    </Button>
                  ) : (
                    <Loader
                      type="ThreeDots"
                      color="#778AAC"
                      height={80}
                      width={80}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  );
});

export default Checkout;
